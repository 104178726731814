<template>
    <div>
        <b-card class="mb-3">
            <b-button v-if="client.Status == 'Active'" variant="outline-primary" @click="$refs.clientRequests.add()" class="mr-3"><i class="fas fa-exchange-alt"></i> Request Service Adjustment</b-button>
            <b-button v-if="client.Status == 'Active'" variant="outline-primary" @click="$refs.inquiries.add()" class="mr-3"><i class="far fa-question-circle"></i> Inquiry</b-button>
            <b-button variant="outline-primary" @click="print()"><i class="fas fa-print"></i> Print</b-button>
        </b-card>
        <b-card>
            <span class="text-right" :class="getStatusClass(client)">{{client.Status}}</span>
            <div class="h4">{{client.FirstName}} {{client.LastName}}</div>
            <div class="h5"><address-data :address1="client.Address1" address2="client.Address2" :city="client.City" :state="client.State" :zip="client.PostalCode" /></div>
            <b-row>
                <b-col v-if="client.PhoneHome" cols="auto"><phone-data prefix="Main: " :phoneNumber="client.PhoneHome" /></b-col>
                <b-col v-if="client.PhoneCell" cols="auto"><phone-data prefix="Cell: " :phoneNumber="client.PhoneCell" /></b-col>
                <b-col v-if="client.PhoneWork" cols="auto"><phone-data prefix="Work: " :phoneNumber="client.PhoneWork" /></b-col>
            </b-row>
            <div>
                <email-data :emailAddress="client.EmailAddress" />
            </div>
        </b-card>
        <b-card no-body class="mt-3">
            <b-tabs card>
                <b-tab title="Enrollment Info">
                    <b-row>
                        <b-col v-if="client.Birthdate" cols="auto">
                            <span class="text-muted">Date of Birth:</span> {{client.Birthdate|longDate}}
                        </b-col>
                        <b-col v-if="client.Gender" cols="auto">
                            <span class="text-muted">Gender:</span> {{client.Gender}}
                        </b-col>
                        <b-col v-if="client.Race" cols="auto">
                            <span class="text-muted">Race:</span> {{client.Race}}
                        </b-col>
                        <b-col v-if="client.EyeColor" cols="auto">
                            <span class="text-muted">Eye Color:</span> {{client.EyeColor}}
                        </b-col>
                        <b-col v-if="client.HairColor" cols="auto">
                            <span class="text-muted">Hair Color:</span> {{client.HairColor}}
                        </b-col>
                    </b-row>
                    <pre v-if="client.Comments" class="text-wrap border rounded p-3 mt-3">{{client.Comments}}</pre>
                    <hr />
                    <b-row>
                        <b-col class="auto">
                            <span class="text-muted">Court:</span> {{client.Court ? getCourtName(client.Court) : 'n/a'}}
                        </b-col>
                        <b-col class="auto">
                            <span class="text-muted">Judge:</span> {{client.Judge ? getJudgeName(client.Judge) : 'n/a'}}
                        </b-col>
                        <b-col class="auto">
                            <span class="text-muted">Docket:</span> {{client.Docket ? client.Docket : 'n/a'}}
                        </b-col>
                        <b-col class="auto">
                            <span class="text-muted">Charge(s):</span> {{client.Charges ? client.Charges : 'n/a'}}
                        </b-col>
                    </b-row>
                    <div>
                        <span class="text-muted">Case Manager / Probation Officer Name:</span> {{client.CaseManagerProbationOfficerName ? client.CaseManagerProbationOfficerName : 'n/a'}} <span v-if="client.CaseManagerProbationOfficerEmail">({{client.CaseManagerProbationOfficerEmail}})</span>
                    </div>
                    <b-row>
                        <b-col>
                            <span class="text-muted">In Custody:</span> {{client.InCustody ? client.InCustody : 'n/a'}}
                        </b-col>
                        <b-col v-if="client.InCustody == 'Yes'">
                            <span class="text-muted">Cleared:</span> {{client.InCustodyCleared ? client.InCustodyCleared : 'n/a'}}
                        </b-col>
                        <b-col v-if="client.InCustody == 'Yes'">
                            <span class="text-muted">Holds:</span> {{client.InCustodyAnyHolds ? client.InCustodyAnyHolds : 'n/a'}}
                        </b-col>
                        <b-col v-if="client.InCustody == 'Yes'">
                            <span class="text-muted">Location:</span> {{client.InCustodyLocation ? client.InCustodyLocation : 'n/a'}}
                        </b-col>
                    </b-row>
                    <hr />
                    <div>
                        <div>{{client.Payment.CourtPay == 'Yes' ? 'Program is Paying' : 'Enrollee is Paying'}}</div>
                        <div v-if="client.Payment.CourtPay == 'Yes'">
                            From {{client.Payment.CourtPayStartDate|shortDate}} to <span v-if="client.Payment.CourtPayDateUntil != 'Specific Date...'">{{client.Payment.CourtPayDateUntil}}</span><span v-else>{{client.Payment.CourtPayEndDate|shortDate}}</span>
                        </div>
                        <div v-else>
                            Initial payment is {{client.Payment.ExpectedMethodOfInitialPayment}}
                        </div>
                    </div>
                    <hr />
                    <b-tabs>
                        <b-tab v-for="(item, index) in enrollments" :key="index">
                            <template #title>
                                <i v-if="!item.CompletedOn" v-b-popover.hover.top="'Pending Processing by HCI'" class="fa fa-exclamation-triangle text-danger"></i>
                                {{item.CreatedOn|shortDate}}
                            </template>
                            <div v-if="item.Type == 'Request'" class="alert alert-info mt-3">
                                Requested by {{item.CreatedBy}} on {{item.CreatedOn|shortDate}} at {{item.CreatedOn|shortTime}}<span v-if="item.CompletedOn">, completed on {{item.CompletedOn|shortDate}} at {{item.CompletedOn|shortTime}}</span>
                            </div>
                            <enrollment-view class="mt-3" :subject="item.Data" />
                        </b-tab>
                    </b-tabs>
                </b-tab>
                <client-inquiries ref="inquiries" :id="id" />
                <client-notifications :id="id" />
                <b-tab v-if="serviceInfo.NonCompliance">
                    <template #title>
                        Non-Compliance
                        <b-badge variant="secondary" pill class="ml-2 font-weight-normal">{{serviceInfo.NonCompliance.length}}</b-badge>
                    </template>
                    <b-list-group v-if="serviceInfo.NonCompliance.length">
                        <b-list-group-item v-for="(item, index) in serviceInfo.NonCompliance" :key="index">
                            <small class="text-muted">{{item.ConnoncomCreatedOn|longDateTime}}</small>
                            <div>{{item.ConnoncomUnitType}}<span v-if="item.ConnoncomInstallationDate"> installed on {{item.ConnoncomInstallationDate|longDateTime}}</span></div>
                            <pre v-if="item.ConnoncomNotes" class="text-wrap border rounded p-3 mt-1">{{item.ConnoncomNotes}}</pre>
                        </b-list-group-item>
                    </b-list-group>
                    <div class="p-3 border rounded text-muted text-center" v-else>
                        None Found
                    </div>
                </b-tab>
                <client-requests v-if="converted" ref="clientRequests" :id="id" :subject="client" />
                <b-tab v-if="serviceInfo.Services">
                    <template #title>
                        Services
                        <b-badge variant="secondary" pill class="ml-2 font-weight-normal">{{serviceInfo.Services.length}}</b-badge>
                    </template>
                    <b-list-group v-if="serviceInfo.Services.length">
                        <b-list-group-item v-for="(item, index) in serviceInfo.Services" :key="index">
                            <small class="text-muted">From {{item.ConserStartDate|shortDate}} to <span v-if="item.ConserEndDate">{{item.ConserEndDate|shortDate}}</span><span v-else>Undetermined</span></small>
                            <div>{{item.SerName}}</div>
                            <small class="text-muted"><span v-if="item.SerEnrollmentFee">Enrollment Fee: {{item.SerEnrollmentFee|currency}}, </span>Daily Cost: {{item.SerCost|currency}}</small>
                        </b-list-group-item>
                    </b-list-group>
                    <div class="p-3 border rounded text-muted text-center" v-else>
                        None Found
                    </div>
                </b-tab>
                <b-tab v-if="serviceInfo.TetherVerifications">
                    <template #title>
                        Tether Verifications
                        <b-badge variant="secondary" pill class="ml-2 font-weight-normal">{{serviceInfo.TetherVerifications.length}}</b-badge>
                    </template>
                    <b-list-group v-if="serviceInfo.TetherVerifications.length">
                        <b-list-group-item v-for="(item, index) in serviceInfo.TetherVerifications" :key="index">
                            <small class="text-muted">{{item.ContetverCreatedOn|longDateTime}}</small>
                            <div>{{item.ContetverUnitType}}<span v-if="item.ContetverInstallationDate"> installed {{item.ContetverInstallationLocation}} on {{item.ContetverInstallationDate|longDateTime}}</span></div>
                            <pre v-if="item.ContetverNotes" class="text-wrap border rounded p-3 mt-1">{{item.ContetverNotes}}</pre>
                        </b-list-group-item>
                    </b-list-group>
                    <div class="p-3 border rounded text-muted text-center" v-else>
                        None Found
                    </div>
                </b-tab>
                <b-tab v-if="serviceInfo.Terminations">
                    <template #title>
                        Terminations
                        <b-badge variant="secondary" pill class="ml-2 font-weight-normal">{{serviceInfo.Terminations.length}}</b-badge>
                    </template>
                    <b-list-group v-if="serviceInfo.Terminations.length">
                        <b-list-group-item v-for="(item, index) in serviceInfo.Terminations" :key="index">
                            <small class="text-muted">{{item.ContermCreatedOn|longDateTime}}</small>
                            <div>Terminated on {{item.ContermDate|shortDate}}</div>
                        </b-list-group-item>
                    </b-list-group>
                    <div class="p-3 border rounded text-muted text-center" v-else>
                        None Found
                    </div>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script lang="ts">
    import AddressData from '../data/Address';
    import ClientBase from '../mixins/ClientBase';
    import ClientInquiries from './ClientInquiries';
    import ClientNotifications from './ClientNotifications';
    import ClientRequests from './ClientRequests';
    import EmailData from '../data/Email';
    import EnrollmentView from './EnrollmentView';
    import PhoneData from '../data/Phone';
    export default {
        mixins: [ClientBase],
        components: {
            AddressData,
            ClientInquiries,
            ClientNotifications,
            ClientRequests,
            EmailData,
            EnrollmentView,
            PhoneData
        },
        props: ['id', 'client', 'courts', 'judges', 'created-on', 'converted'],
        data() {
            return {
                serviceInfo: {},
                serviceRequests: [],
            };
        },
        mounted() {
            this.getServiceInfo();
        },
        methods: {
            getCourtName(id) {
                if (id == "Other") return this.client.OtherCourt;
                return Number.isInteger(id) ? this.courts.find(o => o.value == id).text : id;
            },
            getJudgeName(id) {
                if (!Number.isInteger(id)) return id == "Other" ? this.client.OtherJudge : id;
                return this.judges ? this.judges.find(o => o.value == id).text : id;
            },
            async getServiceInfo() {
                const response = await this.$remote.request('Client', 'GetServiceInfo', { Id: this.id });
                if (response) {
                    this.serviceInfo = response;
                }
                await this.getServiceRequests();
            },
            async getServiceRequests() {
                const response = await this.$remote.request('ClientServiceRequest', 'GetForClient', { CliId: this.id });
                if (response) {
                    this.serviceRequests = response.data;
                }
            },
            async print() {
                this.$remote.clientDocument(this.id);
            }
        },
        computed: {
            enrollments() {
                let enrollments = this.serviceRequests ? this.serviceRequests.filter(o => { return o.RequestType == 'Monitoring Adjustment' }).map((o) => {
                    return Object.assign(o, { Type: 'Request', CompletedOn: o.CompletedOn, CompletedBy: o.CompletedBy, CreatedOn: o.CreatedOn, CreatedBy: o.CreatedBy, Data: JSON.parse(o.RequestData).Enrollment })
                }) : [];
                if (this.client) enrollments.push({ Type: 'Enrollment', CompletedOn: this.createdOn, CreatedOn: this.createdOn, Data: this.client.Enrollment });
                return enrollments;
            }
        }
    }
</script>

<style scoped>
</style>