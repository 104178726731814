<template>
    <div v-if="isValid">
        <a :href="'mailto:' + emailAddress">{{emailAddress | email}}</a>
    </div>
</template>
<script>
    export default {
        props: {
            emailAddress: String
        },
        computed: {
            isValid() {
                const res = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                return res.test(String(this.emailAddress).toLowerCase());
            }
        }
    }
</script>