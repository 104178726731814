<template>
    <ValidationObserver ref="form">
        <b-button variant="link" v-if="client && type == 'Inclusion'" @click="copyClientAddress">Click here to copy client address.</b-button>
        <b-form-row>
            <b-col>
                <ValidationProvider :rules="{required: true}" name="Name">
                    <b-form-group label="Name" slot-scope="{ valid, errors }">
                        <b-form-input v-model="form.Name" :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col>
                <ValidationProvider :rules="{required: true}" name="Location Type">
                    <b-form-group label="Location Type" slot-scope="{ valid, errors }">
                        <b-form-select v-model="form.LocationType" :state="errors[0] ? false : null" :options="['Home', 'Work', 'Other']" />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
        </b-form-row>
        <b-form-row>
            <b-col>
                <ValidationProvider :rules="{required: true}" name="Address 1">
                    <b-form-group label="Address 1" slot-scope="{ valid, errors }">
                        <b-form-input v-model="form.Address1" :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col>
                <ValidationProvider name="Address 2">
                    <b-form-group label="Address 2" slot-scope="{ valid, errors }">
                        <b-form-input v-model="form.Address2" :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
        </b-form-row>
        <b-form-row>
            <b-col cols="6">
                <ValidationProvider :rules="{required: true}" name="City">
                    <b-form-group label="City" slot-scope="{ valid, errors }">
                        <b-form-input v-model="form.City" :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col cols="2">
                <ValidationProvider :rules="{required: true, max: 2}" name="State">
                    <b-form-group label="State" slot-scope="{ valid, errors }">
                        <b-form-input v-model="form.State" :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col cols="4">
                <ValidationProvider :rules="{required: true, max: 10}" name="Zip">
                    <b-form-group label="Zip" slot-scope="{ valid, errors }">
                        <b-form-input v-model="form.PostalCode" :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
        </b-form-row>
        <b-form-row>
            <b-col>
                <ValidationProvider :rules="{required: false}" name="Phone">
                    <b-form-group label="Phone" slot-scope="{ valid, errors }">
                        <b-form-input v-model="form.PhoneHome" :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col>
                <ValidationProvider rules="" name="Cell Phone">
                    <b-form-group label="Cell Phone" slot-scope="{ valid, errors }">
                        <b-form-input v-model="form.PhoneCell" :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col>
                <ValidationProvider rules="" name="Work Phone">
                    <b-form-group label="Work Phone" slot-scope="{ valid, errors }">
                        <b-form-input v-model="form.PhoneWork" :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
        </b-form-row>
        <ValidationProvider rules="email" name="Email">
            <b-form-group label="Email" slot-scope="{ valid, errors }">
                <b-form-input v-model="form.EmailAddress" :state="errors[0] ? false : null" />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
        </ValidationProvider>
        <div v-if="type == 'Inclusion'">
            <b-form-group>
                <b-form-checkbox v-model="form.ConfinedToLocation" value="Yes" unchecked-value="No"> Confined to Location</b-form-checkbox>
            </b-form-group>
            <schedule v-if="!loading" ref="schedule" :current="form.Schedule" :include-schedule="form.ConfinedToLocation != 'Yes'" @updated="onScheduleUpdated" />
            <b-form-row v-if="false">
                <b-col>
                    <ValidationProvider :rules="{required: true}" name="Start Date">
                        <b-form-group label="Start Date" slot-scope="{ valid, errors }">
                            <b-form-input type="date" v-model="form.StartDate" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col>
                    <ValidationProvider name="End Date">
                        <b-form-group label="End Date" slot-scope="{ valid, errors }">
                            <b-form-input type="date" v-model="form.EndDate" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-form-row>
        </div>
        <ValidationProvider name="Notes">
            <b-form-group label="Notes" slot-scope="{ valid, errors }">
                <b-form-textarea v-model="form.Notes" :state="errors[0] ? false : null" />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
        </ValidationProvider>
    </ValidationObserver>
</template>
<script>
    import Schedule from './Schedule';
    export default {
        components: {
            Schedule
        },
        props: ['client', 'subject', 'index', 'type', 'start-date', 'end-date'],
        data() {
            return {
                loading: true,
                form: {
                    Schedule: {}
                }
            };
        },
        mounted() {
            this.form = this.subject;
            this.loading = false;
        },
        methods: {
            copyClientAddress() {
                this.form = Object.assign({}, this.form, {
                    Address1: this.client.Address1,
                    Address2: this.client.Address2,
                    City: this.client.City,
                    State: this.client.State,
                    PostalCode: this.client.PostalCode,
                    PhoneHome: this.client.PhoneHome,
                    PhoneCell: this.client.PhoneCell,
                    PhoneWork: this.client.PhoneWork,
                    EmailAddress: this.client.EmailAddress,
                });
            },
            onFormUpdated(data) {
                this.form = Object.assign({}, this.form, data);
            },
            onScheduleUpdated(schedule) {
                this.form.Schedule = schedule;
            },
            async validate() {
                let valid = await this.$refs.form.validate();
                if (valid && this.$refs.schedule) {
                    valid = await this.$refs.schedule.validate();
                }
                return valid;
            }
        },
        watch: {
            'form': {
                handler: function (val) {
                    this.$emit('updated', val, this.index, this.type);
                },
                deep: true
            },
            'form.StartDate'(val, oldVal) {
                if (val != oldVal) {
                    this.$emit('service-start-date-changed', val);
                }
            },
            'form.EndDate'(val, oldVal) {
                if (val != oldVal) {
                    this.$emit('service-end-date-changed', val);
                }
            },
        }
    }
</script>