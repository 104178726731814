<template>
    <div v-if="city">
        <span v-if="address1">{{address1}}<span v-if="address2"> {{address2}}</span>, </span>
        {{city}}, {{state}} {{zip}} <a v-if="showMapLink" href="javascript: void(0);" @click="openMap"><i class="fa fa-fw fa-map"></i></a>
    </div>
</template>
<script>
    export default {
        props: {
            address1: {},
            address2: {},
            city: {},
            state: {},
            zip: {},
            showMapLink: {
                default: true
            }
        },
        methods: {
            openMap() {
                window.open('https://maps.google.com?q=' + this.plain)
            }
        },
        computed: {
            plain() {
                return this.address1 + ', ' + this.city + ' ' + this.state + ' ' + this.zip;
            }
        }
    }
</script>