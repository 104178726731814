<template>
    <div>
        <span v-if="prefix" class="text-muted">{{prefix}}</span>
        <a :href="'tel:' + phoneNumber">{{phoneNumber | phone}}</a>
    </div>
</template>
<script>
    export default {
        props: {
            phoneNumber: String,
            prefix: null
        }
    }
</script>