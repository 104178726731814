<template>
    <ValidationObserver ref="form">
        <b-form-row>
            <b-col>
                <ValidationProvider rules="required" name="Start Date">
                    <b-form-group label="Start Date" slot-scope="{ valid, errors }">
                        <b-form-input type="date" v-model="form.StartDate" :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col>
                <ValidationProvider name="End Date">
                    <b-form-group label="End Date" slot-scope="{ valid, errors }">
                        <b-form-input type="date" v-model="form.EndDate" :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
        </b-form-row>
        <div v-if="includeSchedule">
            <ValidationProvider rules="required" name="From Time">
                <b-form-group label="Schedule" slot-scope="{ valid, errors }">
                    <b-form-radio-group v-model="form.Schedule" :options="['Daily', 'Specific']"></b-form-radio-group>
                    <b-form-invalid-feedback :style="errors.length ? 'display: block;' : ''">{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
            </ValidationProvider>
            <transition name="fade">
                <table class="table" v-if="form.Schedule == 'Daily'" key="daily">
                    <thead>
                        <tr>
                            <th class="font-weight-normal">From Time</th>
                            <th class="font-weight-normal">To Time</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in form.ScheduleTimes" :key="index">
                            <td cols="5">
                                <ValidationProvider rules="required" name="From Time">
                                    <b-form-group slot-scope="{ valid, errors }" class="m-0">
                                        <form-input-time v-model="item.From" value-format="hh:mma" :state="errors[0] ? false : null"></form-input-time>
                                    </b-form-group>
                                </ValidationProvider>
                            </td>
                            <td cols="5">
                                <ValidationProvider rules="required" name="To Time">
                                    <b-form-group slot-scope="{ valid, errors }" class="m-0">
                                        <form-input-time v-model="item.To" value-format="hh:mma" :state="errors[0] ? false : null"></form-input-time>
                                    </b-form-group>
                                </ValidationProvider>
                            </td>
                            <td>
                                <transition name="fade">
                                    <b-form-group class="m-0">
                                        <b-button v-if="form.ScheduleTimes.length > 1" variant="outline-secondary" @click="form.ScheduleTimes.splice(index, 1);"><i class="fa fa-times"></i></b-button>
                                    </b-form-group>
                                </transition>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="3"><b-button variant="outline-secondary" @click="form.ScheduleTimes.push({});"><i class="fa fa-plus"></i> Add Time Span</b-button></td>
                        </tr>
                    </tfoot>
                </table>
                <table class="table" v-else-if="form.Schedule == 'Specific'" key="specific">
                    <thead>
                        <tr>
                            <th></th>
                            <th>From Time</th>
                            <th>To Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in form.ScheduleDays" :key="index">
                            <td class="align-middle">
                                <b-form-checkbox v-model="item.Selected" :value="true" :unchecked-value="false">{{item.Day}}</b-form-checkbox>
                            </td>
                            <td>
                                <ValidationProvider :rules="{required: item.Selected}" name="From Time">
                                    <b-form-group slot-scope="{ valid, errors }" class="m-0">
                                        <form-input-time v-model="item.TimeFrom" value-format="hh:mma" :state="errors[0] ? false : null"></form-input-time>
                                    </b-form-group>
                                </ValidationProvider>
                            </td>
                            <td>
                                <ValidationProvider :rules="{required: item.Selected}" name="To Time">
                                    <b-form-group slot-scope="{ valid, errors }" class="m-0">
                                        <form-input-time v-model="item.TimeTo" value-format="hh:mma" :state="errors[0] ? false : null"></form-input-time>
                                    </b-form-group>
                                </ValidationProvider>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </transition>
        </div>
    </ValidationObserver>
</template>
<script>
    import Vue from 'vue';
    import FormInputTime from '../form/FormInputTime';
    export default {
        components: {
            FormInputTime,
        },
        props: {
            current: {},
            includeSchedule: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                form: {},
            };
        },
        mounted() {
            Vue.set(this, 'form', this.current ? this.current : {});
        },
        methods: {
            async validate() {
                let valid = await this.$refs.form.validate();
                if (this.form.Schedule == 'Specific') {
                    valid = this.form.ScheduleDays.filter(o => o.Selected == true).length > 0;
                }
                return valid;
            }
        },
        watch: {
            form: {
                handler(val) {
                    this.$emit('updated', val);
                },
                deep: true,
            },
            'form.Schedule'(val, oldVal) {
                if (val != oldVal) {
                    if (val == 'Specific') {
                        Vue.set(this.form, 'ScheduleDays', this.form.ScheduleDays && this.form.ScheduleDays.length ? this.form.ScheduleDays : [
                            { Day: 'Monday', Selected: false, TimeFrom: '', TimeTo: '' },
                            { Day: 'Tuesday', Selected: false, TimeFrom: '', TimeTo: '' },
                            { Day: 'Wednesday', Selected: false, TimeFrom: '', TimeTo: '' },
                            { Day: 'Thursday', Selected: false, TimeFrom: '', TimeTo: '' },
                            { Day: 'Friday', Selected: false, TimeFrom: '', TimeTo: '' },
                            { Day: 'Saturday', Selected: false, TimeFrom: '', TimeTo: '' },
                            { Day: 'Sunday', Selected: false, TimeFrom: '', TimeTo: '' },
                        ]);
                        Vue.set(this.form, 'ScheduleTimes', []);
                    } else {
                        Vue.set(this.form, 'ScheduleDays', []);
                        Vue.set(this.form, 'ScheduleTimes', this.form.ScheduleTimes && this.form.ScheduleTimes.length ? this.form.ScheduleTimes : [{}]);
                        
                    }
                }
            }
        }
    }
</script>