<template>
    <transition name="fade" mode="out-in">
        <b-progress variant="secondary" key="loader" v-if="loading" :value="100" :max="100" animated></b-progress>
        <div v-else key="interface">
            <b-breadcrumb :items="[{text: 'Enrollee List', to: {name: 'ClientList'}}, {text: title, active: true}]"></b-breadcrumb>
            <div v-if="alerts.length">
                <b-alert v-for="(item, index) in alerts" :key="index" :variant="item.variant" v-html="item.message" show></b-alert>
            </div>
            <ValidationObserver v-if="showForm" ref="form" v-slot="{ invalid }">
                <b-card title="Personal Information">
                    <b-form-row>
                        <b-col>
                            <ValidationProvider ref="ValidationProviderFirstName" :rules="{required: true}" name="First Name">
                                <b-form-group label="First Name" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.FirstName" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col v-if="false">
                            <ValidationProvider :rules="{required: false}" name="Middle Name">
                                <b-form-group label="Middle Name" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.MiddleName" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider ref="ValidationProviderLastName" :rules="{required: true}" name="Last Name">
                                <b-form-group label="Last Name" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.LastName" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col>
                            <ValidationProvider :rules="{required: true}" name="Address 1">
                                <b-form-group label="Address 1" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.Address1" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider rules="" name="Address 2">
                                <b-form-group label="Address 2" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.Address2" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col cols="6">
                            <ValidationProvider :rules="{required: true}" name="City">
                                <b-form-group label="City" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.City" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="2">
                            <ValidationProvider :rules="{required: true, max: 2}" name="State">
                                <b-form-group label="State" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.State" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="4">
                            <ValidationProvider :rules="{required: true, max: 10}" name="Zip">
                                <b-form-group label="Zip" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.PostalCode" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col>
                            <ValidationProvider :rules="{required: true}" name="Phone">
                                <b-form-group label="Phone" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.PhoneHome" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider rules="" name="Cell Phone">
                                <b-form-group label="Cell Phone" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.PhoneCell" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider rules="" name="Work Phone">
                                <b-form-group label="Work Phone" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.PhoneWork" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-form-row>
                    <ValidationProvider rules="email" name="Email">
                        <b-form-group label="Email" slot-scope="{ valid, errors }">
                            <b-form-input v-model="form.EmailAddress" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <b-form-row>
                        <b-col>
                            <ValidationProvider rules="" name="Date of Birth">
                                <b-form-group label="Date of Birth" slot-scope="{ valid, errors }">
                                    <b-form-input type="date" v-model="form.Birthdate" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider rules="" name="Gender">
                                <b-form-group label="Gender" slot-scope="{ valid, errors }">
                                    <b-form-select v-model="form.Gender" :state="errors[0] ? false : null" :options="genders" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider rules="" name="Race">
                                <b-form-group label="Race" slot-scope="{ valid, errors }">
                                    <b-form-select v-model="form.Race" :state="errors[0] ? false : null" :options="races" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider rules="" name="Eye Color">
                                <b-form-group label="Eye Color" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.EyeColor" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider rules="" name="Hair Color">
                                <b-form-group label="Hair Color" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.HairColor" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-form-row>
                    <ValidationProvider rules="" name="Comments">
                        <b-form-group label="Comments / Instructions" slot-scope="{ valid, errors }">
                            <b-form-textarea v-model="form.Comments" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                </b-card>
                <b-card title="Court Information" class="mt-3">
                    <b-form-row>
                        <b-col>
                            <ValidationProvider rules="" name="Court">
                                <b-form-group label="Court" slot-scope="{ valid, errors }">
                                    <b-form-select v-if="allowCourtSelection" v-model="form.Court" :state="errors[0] ? false : null" :options="courts" />
                                    <div v-else class="mt-2">
                                        {{selectedCourt.text}}
                                    </div>
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col v-if="form.Court == 'Other'">
                            <ValidationProvider rules="" name="Other Court, Please Specify...">
                                <b-form-group label="Other Court, Please Specify..." slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.OtherCourt" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider rules="" name="Judge / Magistrate">
                                <b-form-group label="Judge / Magistrate" slot-scope="{ valid, errors }">
                                    <b-form-select v-model="form.Judge" :state="errors[0] ? false : null" :options="judges" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col v-if="form.Judge == 'Other'">
                            <ValidationProvider rules="" name="Other Judge, Please Specify">
                                <b-form-group label="Other Judge / Magistrate, Please Specify" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.OtherJudge" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col>
                            <ValidationProvider rules="" name="Case Number">
                                <b-form-group label="Case Number" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.CaseNumber" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider rules="" name="Charge(s)">
                                <b-form-group label="Charge(s)" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.Charges" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col>
                            <ValidationProvider rules="" name="Case Manager / Probation Officer Name">
                                <b-form-group label="Case Manager / Probation Officer Name" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.CaseManagerProbationOfficerName" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider rules="email" name="Case Manager / Probation Officer Email">
                                <b-form-group label="Case Manager / Probation Officer Email" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.CaseManagerProbationOfficerEmail" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col>
                            <ValidationProvider rules="" name="In Custody?">
                                <b-form-group label="In Custody" slot-scope="{ valid, errors }">
                                    <b-form-radio-group v-model="form.InCustody" :options="['Yes', 'No']" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col v-if="form.InCustody == 'Yes'">
                            <ValidationProvider rules="" name="Cleared?">
                                <b-form-group label="Cleared" slot-scope="{ valid, errors }">
                                    <b-form-radio-group v-model="form.InCustodyCleared" :options="['Yes', 'No']" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col v-if="form.InCustody == 'Yes'">
                            <ValidationProvider rules="" name="Are there any holds?">
                                <b-form-group label="Are there any holds?" slot-scope="{ valid, errors }">
                                    <b-form-radio-group v-model="form.InCustodyAnyHolds" :options="['Yes', 'No']" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col v-if="form.InCustody == 'Yes'">
                            <ValidationProvider rules="" name="Location">
                                <b-form-group label="Location" slot-scope="{ valid, errors }">
                                    <b-form-input v-model="form.InCustodyLocation" :state="errors[0] ? false : null" />
                                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-form-row>
                </b-card>
                <b-card title="Payment Information" class="mt-3">
                    <ValidationProvider :rules="{required: true}" name="Payment Information">
                        <b-form-group slot-scope="{ valid, errors }">
                            <b-form-radio-group v-model="form.Payment.CourtPay" name="form.Payment.CourtPay" :options="[{text: 'Enrollee is paying', value: 'No'},{text: 'Program is paying', value: 'Yes'}]" />
                            <b-form-invalid-feedback :style="errors.length ? 'display: block;' : ''">{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <div v-if="form.Payment.CourtPay == 'Yes'">
                        <div class="mb-2">Enter the date ranges the program is willing to cover payments for:</div>
                        <b-form-row class="pl-3">
                            <b-col cols="4" xl="3">
                                <ValidationProvider rules="required" name="Start Date">
                                    <b-form-group label="Start Date" slot-scope="{ valid, errors }">
                                        <b-form-input type="date" v-model="form.Payment.CourtPayStartDate" :state="errors[0] ? false : null" />
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="auto">
                                <ValidationProvider rules="required" name="Until...">
                                    <b-form-group label="Until..." slot-scope="{ valid, errors }">
                                        <b-form-radio-group v-model="form.Payment.CourtPayDateUntil" :options="['Further Notice', 'Specific Date...']" class="mt-2"></b-form-radio-group>
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col cols="4" xl="3" v-if="form.Payment.CourtPayDateUntil == 'Specific Date...'">
                                <ValidationProvider rules="required" name="End Date">
                                    <b-form-group label="End Date" slot-scope="{ valid, errors }">
                                        <b-form-input type="date" v-model="form.Payment.CourtPayEndDate" :state="errors[0] ? false : null" />
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-form-row>
                    </div>
                    <b-form-row v-else-if="form.Payment.CourtPay == 'No'">
                        <b-col cols="12" md="6">
                            <b-form-group label="Expected Method of Initial Payment">
                                <b-form-select v-model="form.Payment.ExpectedMethodOfInitialPayment" :options="['Cash', 'Check', 'Credit Card', 'Money Order', 'Unknown']"></b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-form-row>
                </b-card>
                <b-card title="Enrollment Information" class="mt-3">
                    <ValidationProvider :rules="{required: true}" name="Organization" v-if="organizations.length > 1">
                        <b-form-group label="Organization" slot-scope="{ valid, errors }">
                            <b-form-select v-model="form.Organization" :options="organizations" buttons button-variant="outline-secondary"></b-form-select>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <enrollment-form ref="enrollment" :client="form" @updated="onEnrollmentUpdated" />
                </b-card>
                <hr />
                <div class="text-center">
                    <transition name="fade">
                        <div v-if="invalid" class="text-danger my-3">Unable to save and send for enrollment, one or more fields above need attention. You may save a draft and return at a later time to complete the enrollment.</div>
                    </transition>
                    <transition name="fade">
                        <div v-if="draftError" class="text-danger my-3">{{draftError}}</div>
                    </transition>
                    <b-dropdown text="Save" variant="primary" class="mr-3">
                        <template #button-content>
                            <i class="fa fa-save"></i> Save
                        </template>
                        <b-dropdown-item @click="save(true)">Save and send to enrollment</b-dropdown-item>
                        <b-dropdown-item @click="save(false)">Save as draft</b-dropdown-item>
                    </b-dropdown>
                    <b-button v-if="id" variant="outline-primary" @click="print()" class="mr-3"><i class="fas fa-print"></i> Print</b-button>
                    <b-button v-if="id" variant="danger" @click="remove" class="mr-3"><i class="fa fa-times"></i> Delete</b-button>
                    <b-button variant="outline-secondary" @click="cancel"><i class="fa fa-ban"></i> Cancel</b-button>
                </div>
            </ValidationObserver>
            <div v-else>
                <client-view :id="id" :client="form" :created-on="createdOn" :courts="courts" :judges="judges" :converted="converted" />
            </div>
        </div>
    </transition>
</template>
<script>
    import ClientBase from './mixins/ClientBase';
    import ClientView from './subcomponents/ClientView';
    import EnrollmentForm from './subcomponents/EnrollmentForm';
    export default {
        mixins: [ClientBase],
        components: {
            ClientView,
            EnrollmentForm,
        },
        data() {
            return {
                id: 0,
                loading: true,
                alerts: [],
                converted: false,
                form: {
                    Status: 'Draft',
                    Enrollment: {},
                    Payment: {}
                },
                courts: [],
                genders: [
                    'Male',
                    'Female',
                    'Decline to answer',
                ],
                judges: [],
                organizations: [],
                races: [
                    'African - American / Black',
                    'American Indian / Alaskan Native',
                    'Asian',
                    'Caucasian / White',
                    'Hispanic / Latino',
                    'Native Hawaiian / Other Pacific Islander',
                    'Two or more races',
                ],
                user: {},
                draftError: null,
                createdOn: null,
            };
        },
        async mounted() {
            this.id = this.$route.params.id ?? 0;
            await this.getCourts();
            await this.getOrganizations();
            this.user = await this.$identity.getCurrentUser();
            if (this.id > 0) {
                await this.get();
            }
            if (this.organizations.length == 1) {
                this.form.Organization = this.organizations[0].value;
            }
            if (this.isCourtUser) {
                this.form.Court = this.user.OrganizationsDetails[0].CouId;
                this.form.CaseManagerProbationOfficerName = this.user.FirstName + ' ' + this.user.LastName;
                this.form.CaseManagerProbationOfficerEmail = this.user.Email;
            }
            this.loading = false;
        },
        methods: {
            cancel() {
                this.$router.push('/');
            },
            async getStatusDetails() {
                if (this.form.Status == 'Rejected, Needs Review') {
                    const response = await this.$remote.request('Client', 'GetLastStatusUpdate', { Id: this.id });
                    if (response) this.alerts.push({ variant: 'danger', message: response.replace(/(\r\n|\n\r|\r|\n)/g, '<br>' + '$1') });
                }
            },
            async remove() {
                if (confirm('Are you sure you would like to delete this enrollee record?')) {
                    this.loading = true;
                    await this.$remote.request('Client', 'Delete', { Id: this.id });
                    await this.$router.push({ name: 'ClientList' });
                    this.$bvToast.toast('Enrollee record successfully deleted', {
                        title: 'Deleted',
                        variant: 'success',
                        toaster: 'b-toaster-bottom-center',
                        solid: true,
                        appendToast: true,
                    });
                    this.loading = false;
                }
            },
            async get() {
                const response = await this.$remote.request('Client', 'Get', { Id: this.id });
                if (response) {
                    this.form = JSON.parse(response.Data);
                    this.createdOn = response.CreatedOn;
                    this.converted = response.Converted;
                    await this.getStatusDetails();
                }
            },
            async getCourts() {
                const response = await this.$remote.request('Court', 'List');
                this.courts = response.map((o) => { return { text: o.CouName, value: o.CouId }; });
                this.courts.push({text: 'Other, Specify...', value: 'Other'});
            },
            async getJudges() {
                const response = await this.$remote.request('Court', 'GetJudges', { CouId: this.form.Court });
                this.judges = response.map((o) => { return { text: o.CouConName, value: o.CouConId }; });
                this.judges.push({ text: 'Other, Specify...', value: 'Other' });
            },
            async getOrganizations() {
                const response = await this.$remote.request('Organization', 'List');
                this.organizations = response.map((o) => { return { text: o.OrgName, value: o.OrgId }; });
            },
            onEnrollmentUpdated(enrollment) {
                this.form.Enrollment = enrollment;
            },
            onInquiriesUpdated() {
                console.log('inquiries updated');
            },
            async print() {
                const valid = await this.validateDraft();
                if (valid) {
                    let form = Object.assign({}, this.form);
                    await this.$remote.request('Client', 'Save', { Id: this.id, Data: JSON.stringify(form) });
                    this.$remote.clientDocument(this.id);
                }
            },
            async save(enroll) {
                const valid = enroll ? await this.validate() : await this.validateDraft();
                if (valid) {
                    let form = Object.assign({}, this.form);
                    if (enroll) {
                        if (!confirm('Are you sure you are ready to send for enrollment?')) return;
                        form.Status = 'Sent for Enrollment';
                    }
                    await this.$remote.request('Client', 'Save', { Id: this.id, Data: JSON.stringify(form) });
                    await this.$router.push({ name: 'ClientList' });
                    this.$bvToast.toast('Enrollee information successfully saved', {
                        title: 'Saved',
                        variant: 'success',
                        toaster: 'b-toaster-bottom-center',
                        solid: true,
                        appendToast: true,
                    });
                }
            },
            async validateDraft() {
                this.draftError = null;
                let valid = true;
                const MinimumRequiredRefs = ['ValidationProviderFirstName', 'ValidationProviderLastName'];
                for (let i = 0; i < MinimumRequiredRefs.length; i++) {
                    let validation = await this.$refs[MinimumRequiredRefs[i]].validate();
                    if (!validation.valid) {
                        valid = false;
                        this.draftError = "First and Last Names are required to save a draft.";
                        break;
                    }
                }
                return valid;
            },
            async validate() {
                let errors = 2;
                if (await this.$refs.enrollment.validate()) errors--;
                if (await this.$refs.form.validate()) errors--;
                return errors == 0;
            },
        },
        computed: {
            allowCourtSelection() {
                return !this.isCourtUser || !this.form.Court;
            },
            isCourtUser() {
                return this.user.OrganizationsDetails && this.user.OrganizationsDetails.length && this.user.OrganizationsDetails[0].OrgType == "Court";
            },
            showForm() {
                let show = false;
                show = this.form.Status == 'Draft';
                if (!show) show = this.form.Status == 'Rejected, Needs Review';
                return show;
            },
            title() {
                return this.id ? ('Enrollee #' + this.id) : 'New Enrollee Registration';
            },
            selectedCourt() {
                return this.courts.find((o) => { return o.value == this.form.Court; });
            }
        },
        watch: {
            'form.Court'(val, oldVal) {
                if (val != oldVal) {
                    if (!Number.isInteger(val)) {
                        this.judges = [{ text: 'Other, Specify...', value: 'Other' }];
                        if (this.form.Judge != "Other") this.form.OtherJudge = this.form.Judge;
                        this.form.Judge = "Other";
                    } else {
                        this.getJudges();
                    }
                }
            }
        }
    }
</script>
