var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[(_vm.client && _vm.type == 'Inclusion')?_c('b-button',{attrs:{"variant":"link"},on:{"click":_vm.copyClientAddress}},[_vm._v("Click here to copy client address.")]):_vm._e(),_c('b-form-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":{required: true},"name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Name"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.form.Name),callback:function ($$v) {_vm.$set(_vm.form, "Name", $$v)},expression:"form.Name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":{required: true},"name":"Location Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Location Type"}},[_c('b-form-select',{attrs:{"state":errors[0] ? false : null,"options":['Home', 'Work', 'Other']},model:{value:(_vm.form.LocationType),callback:function ($$v) {_vm.$set(_vm.form, "LocationType", $$v)},expression:"form.LocationType"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1)],1),_c('b-form-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":{required: true},"name":"Address 1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Address 1"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.form.Address1),callback:function ($$v) {_vm.$set(_vm.form, "Address1", $$v)},expression:"form.Address1"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"name":"Address 2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Address 2"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.form.Address2),callback:function ($$v) {_vm.$set(_vm.form, "Address2", $$v)},expression:"form.Address2"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1)],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":{required: true},"name":"City"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"City"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.form.City),callback:function ($$v) {_vm.$set(_vm.form, "City", $$v)},expression:"form.City"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('ValidationProvider',{attrs:{"rules":{required: true, max: 2},"name":"State"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"State"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.form.State),callback:function ($$v) {_vm.$set(_vm.form, "State", $$v)},expression:"form.State"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"rules":{required: true, max: 10},"name":"Zip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Zip"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.form.PostalCode),callback:function ($$v) {_vm.$set(_vm.form, "PostalCode", $$v)},expression:"form.PostalCode"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1)],1),_c('b-form-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":{required: false},"name":"Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Phone"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.form.PhoneHome),callback:function ($$v) {_vm.$set(_vm.form, "PhoneHome", $$v)},expression:"form.PhoneHome"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"","name":"Cell Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Cell Phone"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.form.PhoneCell),callback:function ($$v) {_vm.$set(_vm.form, "PhoneCell", $$v)},expression:"form.PhoneCell"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"","name":"Work Phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Work Phone"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.form.PhoneWork),callback:function ($$v) {_vm.$set(_vm.form, "PhoneWork", $$v)},expression:"form.PhoneWork"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1)],1),_c('ValidationProvider',{attrs:{"rules":"email","name":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Email"}},[_c('b-form-input',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.form.EmailAddress),callback:function ($$v) {_vm.$set(_vm.form, "EmailAddress", $$v)},expression:"form.EmailAddress"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])}),(_vm.type == 'Inclusion')?_c('div',[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"value":"Yes","unchecked-value":"No"},model:{value:(_vm.form.ConfinedToLocation),callback:function ($$v) {_vm.$set(_vm.form, "ConfinedToLocation", $$v)},expression:"form.ConfinedToLocation"}},[_vm._v(" Confined to Location")])],1),(!_vm.loading)?_c('schedule',{ref:"schedule",attrs:{"current":_vm.form.Schedule,"include-schedule":_vm.form.ConfinedToLocation != 'Yes'},on:{"updated":_vm.onScheduleUpdated}}):_vm._e(),(false)?_c('b-form-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":{required: true},"name":"Start Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Start Date"}},[_c('b-form-input',{attrs:{"type":"date","state":errors[0] ? false : null},model:{value:(_vm.form.StartDate),callback:function ($$v) {_vm.$set(_vm.form, "StartDate", $$v)},expression:"form.StartDate"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,false,849121194)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"name":"End Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"End Date"}},[_c('b-form-input',{attrs:{"type":"date","state":errors[0] ? false : null},model:{value:(_vm.form.EndDate),callback:function ($$v) {_vm.$set(_vm.form, "EndDate", $$v)},expression:"form.EndDate"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,false,2421425162)})],1)],1):_vm._e()],1):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Notes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Notes"}},[_c('b-form-textarea',{attrs:{"state":errors[0] ? false : null},model:{value:(_vm.form.Notes),callback:function ($$v) {_vm.$set(_vm.form, "Notes", $$v)},expression:"form.Notes"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }