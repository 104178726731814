<template>
    <b-modal ref="modal" title="Service Adjustment Request" ok-only size="xl">
        <h2 class="mb-1">{{request.RequestType}}</h2>
        <div class="mb-3">
            Requested by <span class="text-info">{{request.CreatedBy}} <span class="text-muted">on</span> {{request.CreatedOn|longDateTime}}</span><span v-if="request.CompletedOn">, completed on <span class="text-success">{{request.CompletedOn|longDateTime}}</span></span>
        </div>
        <enrollment-view :subject="enrollment" v-if="request.RequestType == 'Monitoring Adjustment'" />
        <div v-if="request.RequestType == 'Service Termination'">
            <div>
                <label class="text-muted">Date of Termination</label>
                <div>{{requestData.TerminationDate}}</div>
            </div>
        </div>
        <div v-else-if="request.RequestType == 'Transfer Supervision'">
            <div>
                <label class="text-muted">Name of Organization</label>
                <div>{{requestData.NameOfOrganization}}</div>
            </div>
            <small class="text-muted">Organization can be updated by clicking on the organization name in the header above.</small>
        </div>
    </b-modal>
</template>
<script>
    import EnrollmentView from './EnrollmentView';
    export default {
        components: {
            EnrollmentView
        },
        props: ['request'],
        methods: {
            show() {
                this.$refs.modal.show();
            }
        },
        computed: {
            enrollment() {
                return this.requestData.Enrollment;
            },
            requestData() {
                return JSON.parse(this.request.RequestData);
            }
        }
    }
</script>
