<template>
    <ValidationObserver ref="form">
        <ValidationProvider rules="required" name="Enrollment Type(s)">
            <b-form-group label="Enrollment Type(s)" slot-scope="{ valid, errors }">
                <b-form-checkbox-group v-model="form.Types" :options="['GPS', 'Alcohol Tether', 'Soberlink']"></b-form-checkbox-group>
                <b-form-invalid-feedback :style="errors.length ? 'display: block;' : ''">{{ errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
        </ValidationProvider>
        <transition-group name="fade">
            <b-card v-for="(item, index) in this.form.List" :key="index" :class="index > 0 ? 'mt-3' : ''">
                <b-card-title class="text-primary">
                    {{item.Type}}
                </b-card-title>
                <zones v-if="item.Type == 'GPS'" ref="zones" :zones="item.Zones" :client="client" @updated="onZonesUpdated(index, $event)" />
                <b-card v-if="item.Type == 'Alcohol Tether'" class="mt-3">
                    <b-form-group>
                        <b-form-checkbox v-model="item.WithRadioFrequency" value="Yes" unchecked-value="No" @change="onChangeWithRadioFrequency(index, $event)"> With Radio Frequency</b-form-checkbox>
                    </b-form-group>
                    <transition name="fade">
                        <schedule v-if="item.WithRadioFrequency == 'Yes'" :current="item.Schedule" @updated="onScheduleUpdated(index, $event)" />
                    </transition>
                </b-card>
                <b-card v-if="item.Type == 'Soberlink'" class="mt-3">
                    <ValidationProvider rules="required" name="Testing Type">
                        <b-form-group label="Testing Type" slot-scope="{ valid, errors }">
                            <b-form-radio-group v-model="item.SoberlinkTestingType" :options="['Daily', 'Color Call']" :state="errors[0] ? false : null" @change="onChangeSoberlinkTestingType(index, $event)" />
                            <b-form-invalid-feedback :style="errors.length ? 'display: block;' : ''">{{ errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                    </ValidationProvider>
                    <schedule v-if="item.SoberlinkTestingType == 'Daily'" :current="item.Schedule" @updated="onScheduleUpdated(index, $event)" />
                </b-card>
            </b-card>
        </transition-group>
    </ValidationObserver>
</template>
<script>
    import Schedule from './Schedule';
    import Zones from './Zones';
    export default {
        components: {
            Schedule,
            Zones,
        },
        props: ['client'],
        data() {
            return {
                form: {
                    Types: [],
                    List: [],
                }
            };
        },
        mounted() {
            this.form = Object.assign(this.form, this.client.Enrollment);
        },
        methods: {
            onChangeWithRadioFrequency(index, val) {
                if (val == 'No') {
                    this.form.List[index].Schedule = {};
                }
            },
            onChangeSoberlinkTestingType(index, val) {
                if (val != 'Daily') {
                    this.form.List[index].Schedule = {};
                }
            },
            onScheduleUpdated(index, data) {
                this.form.List[index].Schedule = data;
            },
            onZonesUpdated(index, zones) {
                this.form.List[index].Zones = zones;
            },
            async validate() {
                let errors = 1;
                if (await this.$refs.form.validate()) errors--;
                if (this.$refs.zones) {
                    for (let i = 0; i < this.$refs.zones.length; i++) {
                        errors++;
                        if (await this.$refs.zones[i].validate()) errors--;
                    }
                }
                return errors == 0;
            }
        },
        watch: {
            form: {
                handler(val) {
                    this.$emit('updated', val);
                },
                deep: true,
            },
            'form.Types'(val, oldVal) {
                if (val.length != oldVal.length) {
                    // Remove unhecked
                    this.form.List = this.form.List.filter(o => val.indexOf(o.Type) != -1);
                    // Add checked
                    for (let i = 0; i < val.length; i++) {
                        if (!this.form.List.find(o => o.Type == val[i])) {
                            let type = {Type: val[i], Schedule: {}};
                            switch (val[i]) {
                                case 'GPS':
                                    type = Object.assign(type, {
                                        Zones: {
                                            Inclusion: [],
                                            Exclusion: [],
                                        }
                                    });
                                    break;
                                case 'Alcohol Tether':
                                    type = Object.assign(type, {
                                        WithRadioFrequency: 'No',
                                    });
                                    break;
                                case 'Soberlink':
                                    type = Object.assign(type, {
                                    });
                                    break;
                            }
                            this.form.List.push(type);
                        }
                    }
                }
            },
        }
    }
</script>