<template>
    <b-tab>
        <template #title>
            Notifications
            <b-badge variant="danger" v-if="unacknowledged.length" pill class="ml-2 font-weight-normal">{{unacknowledged.length}}</b-badge>
            <b-badge variant="secondary" pill class="ml-2 font-weight-normal">{{list.length}}</b-badge>
        </template>
        <transition name="fade" mode="out-in">
            <b-progress variant="secondary" key="loader" v-if="loading" :value="100" :max="100" animated></b-progress>
            <div v-else>
                <b-row align-v="center" class="mb-3">
                    <b-col>
                        <span v-if="unacknowledged.length" class="text-danger d-inline-block"><i class="fas fa-exclamation-circle"></i> Click on red circle next to message to acknowledge receipt.</span>
                    </b-col>
                </b-row>
                <b-list-group v-if="list && list.length">
                    <b-list-group-item v-for="(item, index) in list" :key="index">
                        <div>
                            <a v-if="!item.AcknowledgedOn" href="javascript: void(0);" @click="acknowledge(item)"><i class="far fa-circle text-danger mr-2"></i></a>
                            <i v-else-if="item.AcknowledgedOn" class="far fa-check-circle text-muted mr-2"></i>
                            <span class="text-info">{{item.CreatedBy}} <span class="text-muted">on</span> {{item.CreatedOn|longDateTime}}</span>
                        </div>
                        <p class="mt-2 ml-2">{{item.Message|nl2br}}</p>
                    </b-list-group-item>
                </b-list-group>
                <div v-else class="p-3 border rounded text-center text-muted">
                    None Found
                </div>
            </div>
        </transition>
    </b-tab>
</template>
<script>
    export default {
        props: ['id'],
        data() {
            return {
                loading: true,
                form: {},
                list: [],
            };
        },
        async mounted() {
            await this.get();
            this.loading = false;
        },
        methods: {
            async acknowledge(item) {
                await this.$remote.request('ClientNotification', 'Acknowledge', { CliNotId: item.CliNotId });
                await this.get();
                this.$bvToast.toast('Notification was acknowledged', {
                    title: 'Success',
                    variant: 'success',
                    toaster: 'b-toaster-bottom-center',
                    solid: true,
                    appendToast: true,
                });
            },
            async get() {
                const response = await this.$remote.request('ClientNotification', 'GetForClient', { CliId: this.id });
                if (response) {
                    this.list = response.data;
                }
            },
        },
        computed: {
            unacknowledged() {
                return this.list ? this.list.filter((o) => { return !o.AcknowledgedOn; }) : [];
            }
        }
    }
</script>