<template>
    <div>
        <transition name="fade">
            <div v-if="validationErrors.length" v-html="validationErrors.join('<br/>')" class="text-danger my-3"></div>
        </transition>
        <b-card title="Inclusion Zones" class="mt-3">
            <transition-group tag="div" name="fade" class="list-group" v-if="form.Inclusion.length">
                <b-list-group-item v-for="(item, index) in form.Inclusion" :key="'inc' + index">
                    <zone-form ref="inclusionZones" :client="client" :subject="item" :index="index" type="Inclusion" @updated="updateZone" :start-date="lastServiceStartDate" :end-date="lastServiceEndDate" @service-start-date-changed="updateLastServiceStartDate" @service-end-date-changed="updateLastServiceEndDate" />
                    <div class="text-center">
                        <b-button variant="link" @click="removeZone('Inclusion', item, index)">remove</b-button>
                    </div>
                </b-list-group-item>
            </transition-group>
            <div v-else class="text-muted">
                None Defined
            </div>
            <b-button variant="outline-secondary" class="mt-3" @click="form.Inclusion.push({});"><i class="fa fa-plus"></i> Add <span v-show="form.Inclusion.length">Another</span> Zone</b-button>
            <small class="d-block text-muted mt-3">
                Zone size to be no MORE than 300 feet in radius with no grace period unless stated otherwise
            </small>
        </b-card>
        <b-card title="Exclusion Zones" class="mt-3">
            <transition-group tag="div" name="fade" class="list-group" v-if="form.Exclusion.length">
                <b-list-group-item v-for="(item, index) in form.Exclusion" :key="'exl' + index">
                    <zone-form ref="exclusionZones" :client="client" :subject="item" :index="index" type="Exclusion" @updated="updateZone" :start-date="lastServiceStartDate" :end-date="lastServiceEndDate" @service-start-date-changed="updateLastServiceStartDate" @service-end-date-changed="updateLastServiceEndDate" />
                    <div class="text-center">
                        <b-button variant="link" @click="removeZone('Exclusion', item, index)">remove</b-button>
                    </div>
                </b-list-group-item>
            </transition-group>
            <div v-else class="text-muted">
                None Defined
            </div>
            <b-button variant="outline-secondary" class="mt-3" @click="form.Exclusion.push({});"><i class="fa fa-plus"></i> Add <span v-show="form.Exclusion.length">Another</span> Zone</b-button>
            <small class="d-block text-muted mt-3">
                Zone size to be no LESS than 300 feet in radius with no grace period unless stated otherwise
            </small>
        </b-card>
    </div>
</template>
<script>
    import Vue from 'vue';
    import ZoneForm from './Zone';
    export default {
        components: {
            ZoneForm
        },
        props: ['zones', 'client'],
        data() {
            return {
                form: {
                    Inclusion: [],
                    Exclusion: [],
                },
                lastServiceStartDate: null,
                lastServiceEndDate: null,
                validationErrors: [],
            };
        },
        mounted() {
            Vue.set(this, 'form', Object.assign({}, this.form, this.zones));
        },
        methods: {
            removeZone(type, item, index) {
                this.$delete(this.form[type], index);
            },
            updateLastServiceStartDate(value) {
                this.lastServiceStartDate = value;
            },
            updateLastServiceEndDate(value) {
                this.lastServiceEndDate = value;
            },
            updateZone(zone, index, type) {
                Vue.set(this.form[type], index, zone);
            },
            async validate() {
                this.validationErrors = [];
                if ((!this.$refs.inclusionZones || !this.$refs.inclusionZones.length) && (!this.$refs.exclusionZones || !this.$refs.exclusionZones.length)) {
                    this.validationErrors.push('At least one Inclusion or Exclusion Zone must be defined.');
                    return false;
                }
                let valid = true;
                if (this.$refs.inclusionZones) {
                    for (let i = 0; i < this.$refs.inclusionZones.length; i++) {
                        if (!await this.$refs.inclusionZones[i].validate()) return false;
                    }
                }
                if (this.$refs.exclusionZones) {
                    for (let i = 0; i < this.$refs.exclusionZones.length; i++) {
                        if (!await this.$refs.exclusionZones[i].validate()) return false;
                    }
                }
                return valid;
            }
        },
        watch: {
            form: {
                handler(val) {
                    this.$emit('updated', val);
                },
                deep: true,
            }
        }
    }
</script>