<template>
    <b-tab>
        <template #title>
            Inquiries
            <b-badge variant="danger" v-if="unacknowledged.length" pill class="ml-2 font-weight-normal">{{unacknowledged.length}}</b-badge>
            <b-badge variant="secondary" pill class="ml-2 font-weight-normal">{{list.length}}</b-badge>
        </template>
        <b-modal ref="modal" title="Inquiry">
            <ValidationObserver ref="form">
                <ValidationProvider rules="required" name="Message">
                    <b-form-group label="Message" slot-scope="{ valid, errors }">
                        <b-form-textarea v-model="form.Message" :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
            </ValidationObserver>
            <template #modal-footer="{ ok, cancel, hide }">
                <b-button variant="outline-secondary" @click="hide">Cancel</b-button>
                <b-button variant="primary" @click="save">Submit</b-button>
            </template>
        </b-modal>
        <transition name="fade" mode="out-in">
            <b-progress variant="secondary" key="loader" v-if="loading" :value="100" :max="100" animated></b-progress>
            <div v-else>
                <b-row align-v="center" class="mb-3">
                    <b-col>
                        <span v-if="unacknowledged.length" class="text-danger d-inline-block"><i class="fas fa-exclamation-circle"></i> Click on red circle next to message to acknowledge receipt.</span>
                    </b-col>
                    <b-col class="text-right">
                        <b-button variant="outline-primary" @click="add" class="float-right"><i class="fas fa-plus"></i> Add Inquiry</b-button>
                    </b-col>
                </b-row>
                <b-list-group v-if="list && list.length">
                    <b-list-group-item v-for="(item, index) in list" :key="index">
                        <div>
                            <i v-if="item.IsHcUser != 1" class="fas fa-circle text-muted mr-2"></i>
                            <a v-else-if="item.IsHcUser == 1 && !item.AcknowledgedOn" href="javascript: void(0);" @click="acknowledge(item)"><i class="far fa-circle text-danger mr-2"></i></a>
                            <i v-else-if="item.IsHcUser == 1 && item.AcknowledgedOn" class="far fa-check-circle text-muted mr-2"></i>
                            <span class="text-info">{{item.CreatedBy}} <span class="text-muted">on</span> {{item.CreatedOn|longDateTime}}</span>
                        </div>
                        <p class="mt-2 ml-2 mb-0">{{item.Message|nl2br}}</p>
                        <div v-if="item.FileId">
                            <b-badge class="file-link" href="javascript: void(0);" @click="downloadFile(item)">{{item.FileName}}</b-badge>
                        </div>
                    </b-list-group-item>
                </b-list-group>
                <div v-else class="p-3 border rounded text-center text-muted">
                    None Found
                </div>
            </div>
        </transition>
    </b-tab>
</template>
<script>
    export default {
        props: ['id'],
        data() {
            return {
                loading: true,
                form: {},
                list: [],
            };
        },
        async mounted() {
            await this.get();
            this.loading = false;
        },
        methods: {
            async acknowledge(item) {
                await this.$remote.request('Inquiry', 'Acknowledge', { InqId: item.InqId });
                await this.get();
                this.$bvToast.toast('Inquiry was acknowledged', {
                    title: 'Success',
                    variant: 'success',
                    toaster: 'b-toaster-bottom-center',
                    solid: true,
                    appendToast: true,
                });
            },
            async add() {
                this.$refs.modal.show();
            },
            async downloadFile(item) {
                this.$remote.download(item.FileName, 'Inquiry', 'DownloadFile', { FileId: item.FileId });
            },
            async get() {
                const response = await this.$remote.request('Inquiry', 'GetForClient', { CliId: this.id });
                if (response) {
                    this.list = response.data;
                }
            },
            async save() {
                const valid = await this.$refs.form.validate();
                if (valid) {
                    this.$refs.modal.hide();
                    await this.$remote.request('Inquiry', 'Save', { CliId: this.id, Message: this.form.Message });
                    await this.$emit('updated', this.form);
                    await this.get();
                    this.form = {};
                    this.$bvToast.toast('Inquiry was successfully submitted', {
                        title: 'Success',
                        variant: 'success',
                        toaster: 'b-toaster-bottom-center',
                        solid: true,
                        appendToast: true,
                    });
                }
            }
        },
        computed: {
            unacknowledged() {
                return this.list ? this.list.filter((o) => { return (o.IsHcUser == 1 && !o.AcknowledgedOn); }) : [];
            }
        }
    }
</script>
<style scoped>
    .file-link {
        font-size: 9pt;
        font-weight: normal;
    }
</style>