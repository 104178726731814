<template>
    <b-tab>
        <template #title>
            Requests
            <b-badge variant="danger" v-if="unacknowledged.length" pill class="ml-2 font-weight-normal">{{unacknowledged.length}}</b-badge>
            <b-badge v-if="list" variant="secondary" pill class="ml-2 font-weight-normal">{{list.length}}</b-badge>
        </template>
        <b-modal ref="modal" title="Request Service Adjustment" size="lg">
            <ValidationObserver ref="form">
                <ValidationProvider rules="required" name="Type">
                    <b-form-group label="Type" slot-scope="{ valid, errors }">
                        <b-select v-model="form.Type" :options="types" :state="errors[0] ? false : null" />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                </ValidationProvider>
                <transition name="fade">
                    <div v-if="form.Type">
                        <transition name="fade">
                            <div v-if="form.Type == 'Service Termination'">
                                <ValidationProvider rules="" name="Date of Termination">
                                    <b-form-group label="Date of Termination" slot-scope="{ valid, errors }">
                                        <b-form-input type="date" v-model="form.Data.TerminationDate" :state="errors[0] ? false : null" />
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div v-else-if="form.Type == 'Monitoring Adjustment'">
                                <enrollment-form ref="enrollment" :client="subject" @updated="onFormUpdated($event, 'Enrollment')" />
                            </div>
                            <div v-else-if="form.Type == 'Transfer Supervision'">
                                <ValidationProvider rules="" name="Name of Organization">
                                    <b-form-group label="Name of Organization" slot-scope="{ valid, errors }">
                                        <b-form-input v-model="form.Data.NameOfOrganization" :state="errors[0] ? false : null" />
                                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                        </transition>
                        <ValidationProvider rules="" name="Comments">
                            <b-form-group label="Comments" slot-scope="{ valid, errors }" class="mt-3">
                                <b-form-textarea v-model="form.Data.Comments" :state="errors[0] ? false : null" />
                                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                    </div>
                </transition>
            </ValidationObserver>
            <template #modal-footer="{ ok, cancel, hide }">
                <b-button variant="outline-secondary" @click="hide">Cancel</b-button>
                <b-button variant="primary" @click="save">Submit</b-button>
            </template>
        </b-modal>
        <transition name="fade" mode="out-in">
            <b-progress variant="secondary" key="loader" v-if="loading" :value="100" :max="100" animated></b-progress>
            <div v-else>
                <b-list-group v-if="list && list.length">
                    <b-list-group-item v-for="(item, index) in list" :key="index" href="javascript: void(0);" @click="view(item)">
                        <div>
                            <span class="text-info">{{item.RequestType}}</span> requested by <span class="text-info">{{item.CreatedBy}} <span class="text-muted">on</span> {{item.CreatedOn|longDateTime}}</span><span v-if="item.CompletedOn">, completed on <span class="text-success">{{item.CompletedOn|longDateTime}}</span></span>
                        </div>
                    </b-list-group-item>
                </b-list-group>
                <div v-else class="p-3 border rounded text-center text-muted">
                    None Found
                </div>
            </div>
        </transition>
    </b-tab>
</template>
<script>
    import Vue from 'vue';
    import ClientRequestView from './ClientRequestView';
    import EnrollmentForm from './EnrollmentForm';
    export default {
        components: {
            EnrollmentForm
        },
        props: ['id', 'subject'],
        data() {
            return {
                loading: true,
                form: {
                    Data: {}
                },
                list: [],
            };
        },
        async mounted() {
            await this.get();
            this.loading = false;
        },
        methods: {
            async add() {
                this.$refs.modal.show();
            },
            async get() {
                const response = await this.$remote.request('ClientServiceRequest', 'GetForClient', { CliId: this.id });
                if (response) {
                    this.list = response.data;
                }
            },
            onFormUpdated(data, groupName) {
                if (groupName) {
                    if (!this.form.Data[groupName]) this.form.Data[groupName] = {};
                    this.form.Data[groupName] = Object.assign(this.form.Data[groupName], data);
                } else {
                    this.form.Data = Object.assign(this.form.Data, data);
                }
            },
            async save() {
                const valid = await this.$refs.form.validate();
                if (valid) {
                    this.$refs.modal.hide();
                    await this.$remote.request('ClientServiceRequest', 'Save', { CliId: this.id, Type: this.form.Type, Data: JSON.stringify(this.form.Data) });
                    await this.get();
                    await this.$emit('updated', this.form);
                    this.form = {
                        Data: {}
                    };
                    this.$bvToast.toast('Service request was successfully submitted', {
                        title: 'Success',
                        variant: 'success',
                        toaster: 'b-toaster-bottom-center',
                        solid: true,
                        appendToast: true,
                    });
                }
            },
            view(request) {
                const ComponentClass = Vue.extend(ClientRequestView);
                const instance = new ComponentClass({
                    propsData: { request: request }
                });
                instance.$mount();
                instance.show();
            }
        },
        computed: {
            types() {
                let types = ['Monitoring Adjustment', 'Service Termination', 'Transfer Supervision'];
                types.sort();
                return types;
            },
            unacknowledged() {
                return this.list ? this.list.filter((o) => { return (o.IsHcUser == 1 && !o.AcknowledgedOn); }) : [];
            }
        },
        watch: {
            'form.Type'(val, oldVal) {
                if (val != oldVal) this.form.Data = {};
            }
        }
    }
</script>