<template>
    <b-select :options="times" :value="value" :state="state" @input="$emit('input', $event)">
    </b-select>
</template>
<script>
    import moment from 'moment';
    export default {
        props: {
            value: {
                type: String
            },
            errors: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            valid: {
                type: Boolean
            },
            state: {},
            valueFormat: {
                default: function () {
                    return 'HH:mm:ss';
                }
            },
            textFormat: {
                default: function () {
                    return 'hh:mma';
                }
            }
        },
        data: function () {
            return {
                times: []
            };
        },
        mounted: function () {
            // let time = moment().startOf('day').hour('9');
            // const max = moment().startOf('day').hour('21');
            let time = moment().startOf('day');
            const max = moment().startOf('day').hour('24');
            this.times.push({ value: null, text: '' });
            do {
                this.times.push({ value: time.format(this.valueFormat), text: time.format(this.textFormat) });
                // if (time.format('HH:mm:ss') == )
                time.add(30, 'minutes');
            } while (time.isSameOrBefore(max));
        }
    }
</script>