<template>
    <div>
        <b-card v-for="(enrollment, index) in subject.List" :key="index" :class="(index + 1) != subject.List.length ? 'mb-3' : ''">
            <h6>{{enrollment.Type}}</h6>
            <div v-if="enrollment.Type == 'GPS'">
                <b-card title="Inclusion Zones" class="mt-3">
                    <transition-group tag="div" name="fade" class="list-group" v-if="enrollment.Zones.Inclusion.length">
                        <b-list-group-item v-for="(item, index) in enrollment.Zones.Inclusion" :key="'inc' + index">
                            <b-row>
                                <b-col cols="auto"><span class="text-muted">Name:</span> {{item.Name}}</b-col>
                                <b-col cols="auto"><span class="text-muted">Type:</span> {{item.LocationType}}</b-col>
                            </b-row>
                            <address-data :address1="item.Address1" :address2="item.Address2" :city="item.City" :state="item.State" :zip="item.PostalCode" />
                            <div v-if="item.Schedule.Schedule == 'Daily'" key="EnrollmentScheduleDaily">
                                {{item.Schedule.Schedule}} from {{item.Schedule.StartDate|shortDate}} to {{item.Schedule.EndDate|shortDate}}
                            </div>
                            <div v-else key="EnrollmentScheduleSpecific">
                                <div v-for="day in item.Schedule.ScheduleDays" :key="day" v-show="item.Selected">
                                    {{day.Day}} from {{day.TimeFrom}} to {{day.TimeTo}}
                                </div>
                            </div>
                        </b-list-group-item>
                    </transition-group>
                    <div v-else class="text-muted">
                        None Defined
                    </div>
                </b-card>
                <b-card title="Exclusion Zones" class="mt-3">
                    <transition-group tag="div" name="fade" class="list-group" v-if="enrollment.Zones.Exclusion.length">
                        <b-list-group-item v-for="(item, index) in enrollment.Zones.Exclusion" :key="'inc' + index">
                            <b-row>
                                <b-col cols="auto"><span class="text-muted">Name:</span> {{item.Name}}</b-col>
                                <b-col cols="auto"><span class="text-muted">Type:</span> {{item.LocationType}}</b-col>
                            </b-row>
                            <address-data :address1="item.Address1" :address2="item.Address2" :city="item.City" :state="item.State" :zip="item.PostalCode" />
                        </b-list-group-item>
                    </transition-group>
                    <div v-else class="text-muted">
                        None Defined
                    </div>
                </b-card>
            </div>
            <div v-if="enrollment.Type == 'Alcohol Tether'">
                <span class="text-muted">With Radio Frequency:</span> {{enrollment.WithRadioFrequency ? enrollment.WithRadioFrequency : 'No'}}
            </div>
            <div v-if="enrollment.Type == 'Soberlink'">
                <span class="text-muted">Testing Type:</span> {{enrollment.SoberlinkTestingType}}
            </div>
            <div v-if="enrollment.WithRadioFrequency == 'Yes' || enrollment.SoberlinkTestingType == 'Daily'">
                <b-row>
                    <b-col cols="auto"><span class="text-muted">Start Date:</span> {{enrollment.Schedule.StartDate|longDate}}</b-col>
                    <b-col cols="auto"><span class="text-muted">End Date:</span> <span v-if="enrollment.Schedule.EndDate">{{enrollment.Schedule.EndDate|longDate}}</span><span v-else>Undetermined</span></b-col>
                </b-row>
                <div v-if="enrollment.Schedule == 'Daily'" key="EnrollmentScheduleDaily">
                    {{enrollment.Schedule.Schedule}} from {{enrollment.Schedule.ScheduleDailyFrom}} to {{enrollment.Schedule.ScheduleDailyTo}}
                </div>
                <div v-else key="EnrollmentScheduleSpecific">
                    <div v-for="(item, index) in enrollment.Schedule.ScheduleDays" :key="index" v-show="item.Selected">
                        {{item.Day}} from {{item.TimeFrom}} to {{item.TimeTo}}
                    </div>
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
    import AddressData from '../data/Address';
    export default {
        components: {
            AddressData
        },
        props: ['subject'],
    }
</script>